<template>
  <div style="min-height: calc(100vh - 70px);padding: 10px">
    <el-row :gutter="20">
      <el-col :span="6">
        <div>
        <el-card style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <span>最受欢迎10本书</span>
          </div>
          <div v-for="item in welcomeBookData" :key="item.id">
            <el-descriptions :title="item.book.name" :column="1" @click="$router.push('/front/homeDetail?id=' + item.book.id)">
              <template slot="extra">
                <span> 漂流次数: {{item.number}}</span>
              </template>
              <el-descriptions-item label="作者">{{ item.book.author }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </el-card>
        <el-row>
          <el-col :span="24">
            <div id="main" style="height: 400px;padding: 20px"></div>
          </el-col>

          <el-col :span="24">
            <div id="pie" style="height: 400px;width:400px;padding: 20px"></div>
          </el-col>
        </el-row>
        </div>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-row style="width: 100%">
          <el-col :xs="18" :sm="20" :md="21" :lg="21"  >
          <el-input size="mid" style="width: 100%"  placeholder="请输入名称" suffix-icon="el-icon-search"
                    v-model="name"></el-input>
          </el-col>
          <el-col :xs="6" :sm="4" :md="3" :lg="3"  >
          <el-button class="ml-5" style="width: 100%" type="success"  @click="load" size="mid">搜索</el-button>
          </el-col>
        </el-row>
        <el-card v-for="item in tableData" :key="item.id" style="margin: 10px 0">
          <div style="display: flex" >
            <div style="width: 140px"> <img :src="item.img" alt="" style="width: 100%; height: 160px; border-radius: 10px"></div>
            <div style="padding-left: 50px; flex: 1">
              <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
                <span style="font-size: 24px" @click="$router.push('/front/homeDetail?id=' + item.id)"> {{ item.name }}</span>
              </div>
              <div style="line-height: 30px">
                <div><b style="margin-right: 10px">作者：</b>{{ item.author }}</div>
                <div><b style="margin-right: 10px">出版社：</b>{{ item.publisher }}</div>
                <div><b style="margin-right: 10px">出版年份：</b>{{ item.publishedYear }}</div>
                <div><b style="margin-right: 10px">类型：</b>{{ item.typeText?item.typeText.name:'' }}</div>
                <div style="text-align: right;" >
                  <el-button type="success" v-if="user&&user.id&&!item.bookshelf" @click="addToSelf(item)" slot="reference">添加到书架 <i class="el-icon-notebook-2"></i></el-button>

                  <el-button v-if="user&&user.id&&item.driftRecord&&item.driftRecord.status==='0'"  type="primary" @click="handleBorrow(item)" slot="reference">申请借阅 <i class="el-icon-notebook-1"></i></el-button>
                </div>

              </div>
            </div>
          </div>
        </el-card>
        <div style="margin: 10px 0; padding: 10px; background-color: #fff">
          <el-pagination
              size="mid"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[2, 5, 10, 20]"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </el-col>
      <el-col :span="6">
        <el-card  style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <span>系统书单推荐</span>
          </div>
          <div  v-if="user&&user.id" v-for="item in hotBookData" :key="item.id">
            <el-descriptions :title="item.name" :column="2" @click="$router.push('/front/homeDetail?id=' + item.id)">
              <el-descriptions-item label="出版社">{{ item.publisher }}</el-descriptions-item>
              <el-descriptions-item label="出版年份">{{ item.publishedYear }}</el-descriptions-item>

              <el-descriptions-item label="作者">{{ item.author }}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div v-if="!this.isRequest" style="text-align: center;font-weight: 400;color: #6d7fff">登录后推荐书籍</div>
        </el-card>

      </el-col>
    </el-row>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form ref="dialogForm" :model="borrowForm" label-width="100px" size="small" style="width: 90%">
        <el-form-item label="领取地址：">
          <span>{{borrowForm.driftRecord.address}}</span>
        </el-form-item>
        <el-form-item label="领取电话：">
          <span>{{borrowForm.driftRecord.phone}}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import user from "@/views/User";
import * as echarts from "echarts";
import router from "@/router";

export default {
  name: "MyAnimal",
  data() {
    return {
      tableData: [],
      hotBookData: [],
      welcomeBookData:[],
      borrowForm:{
        driftRecord:{}
      },
      total: 0,
      pageNum: 1,
      pageSize: 5,
      name: "",
      form: {},
      dialogFormVisible: false,
      isRequest:false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },

  mounted() {  // 页面元素渲染后再触发

    var option = {
      title: {
        text: '各季度系统漂流统计',
        subtext: '趋势图',
        left: 'center'
      },

      xAxis: {
        type: 'category',
        data: ["第一季度", "第二季度", "第三季度", "第四季度"]
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [],
          type: 'line'
        },
        {
          data: [],
          type: 'bar'
        }
      ]
    };
    var chartDom = document.getElementById('main');
    var myChart = echarts.init(chartDom);
    this.request.get("/echarts/members").then(res => {
      // 填空
      // option.xAxis.data = res.data.x
      option.series[0].data = res.data
      option.series[1].data = res.data
      // 数据准备完毕之后再set
      myChart.setOption(option);
    })


    // 饼图

    var pieOption ={
          title: {
            text: '前五漂流书籍',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
      legend: {
        orient: 'vertical',
        left: 'left'
      },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                normal: {
                  show: true,
                  position: 'inner',
                  textStyle: {
                    fontWeight: 300,
                    fontSize: 14,
                    color: "#fff"
                  },
                  formatter: '{d}%'
                }
              },
              data: [],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
    ;

    var pieDom = document.getElementById('pie');
    var pieChart = echarts.init(pieDom);

    this.request.get("/echarts/top").then(res => {
      console.log(res.data)
      pieOption.series[0].data = res.data
      pieChart.setOption(pieOption)
    })
  },
  created() {
    this.load()
  },
  methods: {
    handleBorrow(record){
      this.dialogFormVisible = true
      this.borrowForm = record
      console.log(this.borrowForm)
    },
    save() {
      let  form =  this.borrowForm.driftRecord;
      form.userId = this.user.id;
      form.status = "1";
      this.request.post("/driftRecord", form).then(res => {
        if (res.code === '200') {
          this.$message.success("成功")
          this.dialogFormVisible =false;
          router.push('/front/myDriftRecordBook')
        } else {
          this.$message.error("失败")
        }
      })
    },
    load() {
      this.request.get("/book/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      // 加载系统推荐
      this.addHotBook();
      // 加载欢迎的书籍
      this.loadWelcomeBookData();
    },
    // 欢迎的书籍
    loadWelcomeBookData(){
      this.request.get("/book/welcomeBookData").then(res => {
          this.welcomeBookData = res.data
      })
    },
    addHotBook() {
      this.request.get("/user/check").then(res=>{
        if (res.code!=="-1"){
          this.isRequest = true;
        }else {
          this.isRequest = false;
        }
        if (this.isRequest) {
          this.request.get("/book/system").then(res => {
            this.hotBookData = res.data
          })
        }
      })
    },
    addToSelf(record){
      this.request.post("/bookshelf", {
        "bookId":record.id,
        "userId":this.user.id
      }).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee !important;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}
</style>
